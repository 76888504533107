const TRACKING_ID = 'UA-11848551-1';

const setupGoogleAnalytics = () => {
  if (process.env.NODE_ENV === 'production') {
    setupProduction();
  } else {
    setupDevelopment();
  }

  window.ga('create', TRACKING_ID, {
    siteSpeedSampleRate: 50,
    cookieDomain: 'tjoonz.com'
  });

  window.ga('require', 'linkid', 'linkid.js');
};

export default setupGoogleAnalytics;

const setupDevelopment = () => {
  window.ga = (...args) => console.log('Mocked window.ga() call', args);
};

const setupProduction = () => {
  /* eslint-disable */
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
  /* eslint-enable */
};
