export const ADD_TASKS = 'ADD_TASKS';

export const APPEND_PLAYLIST_ITEMS = 'APPEND_PLAYLIST_ITEMS';

export const APPEND_MY_COLLECTIONS_MIXES_RESULTS = 'APPEND_MY_COLLECTIONS_MIXES_RESULTS';

export const APPEND_QUERY_RESULTS = 'APPEND_QUERY_RESULTS';

export const CLOSE_DRAWER = 'CLOSE_DRAWER';

export const COMPLETE_TASKS = 'COMPLETE_TASKS';

export const DELETE_MIX_FROM_MY_COLLECTION = 'DELETE_MIX_FROM_MY_COLLECTION';

export const DONE_FETCHING_FACETTED_SEARCH_OPTIONS = 'DONE_FETCHING_FACETTED_SEARCH_OPTIONS';

export const DONE_FETCHING_MIX = 'DONE_FETCHING_MIX';

export const DONE_FETCHING_MY_COLLECTIONS_RESULTS = 'DONE_FETCHING_MY_COLLECTIONS_RESULTS';

export const DONE_FETCHING_NEXT_MY_COLLECTIONS_RESULTS_PAGE = 'DONE_FETCHING_NEXT_MY_COLLECTIONS_RESULTS_PAGE';

export const DONE_FETCHING_NEXT_QUERY_RESULTS_PAGE = 'DONE_FETCHING_NEXT_QUERY_RESULTS_PAGE';

export const DONE_FETCHING_QUERY_RESULTS = 'DONE_FETCHING_QUERY_RESULTS';

export const DONE_SIGNING_IN = 'DONE_SIGNING_IN';

export const DONE_SUBMITTING_COMMENT = 'DONE_SUBMITTING_COMMENT';

export const DONE_SUBMITTING_VOTE = 'DONE_SUBMITTING_VOTE';

export const OPEN_DRAWER = 'OPEN_DRAWER';

export const PAUSE_PLAYBACK = 'PAUSE_PLAYBACK';

export const PREPEND_COMMENT = 'PREPEND_COMMENT';

export const QUEUE_MY_COLLECTION = 'QUEUE_MY_COLLECTION';

export const REMOVE_ALL_TASKS = 'REMOVE_ALL_TASKS';

export const REMOVE_PLAYLIST_ITEMS = 'REMOVE_PLAYLIST_ITEMS';

export const RENAME_MY_COLLECTION = 'RENAME_MY_COLLECTION';

export const RESUME_PLAYBACK = 'RESUME_PLAYBACK';

export const SET_AUTH = 'SET_AUTH';

export const SET_DETAILS = 'SET_DETAILS';

export const SET_FACETTED_SEARCH_OPTIONS = 'SET_FACETTED_SEARCH_OPTIONS';

export const SET_MIX = 'SET_MIX';

export const SET_MY_COLLECTIONS_CURRENT = 'SET_MY_COLLECTIONS_CURRENT';

export const SET_MY_COLLECTIONS_RESULTS = 'SET_MY_COLLECTIONS_RESULTS';

export const SET_PLAYHEAD = 'SET_PLAYHEAD';

export const SET_QUERY_RESULTS = 'SET_QUERY_RESULTS';

export const SET_VOLUME = 'SET_VOLUME';

export const SKIP_BACKWARD = 'SKIP_BACKWARD';

export const SKIP_FORWARD = 'SKIP_FORWARD';

export const START_FETCHING_FACETTED_SEARCH_OPTIONS = 'START_FETCHING_FACETTED_SEARCH_OPTIONS';

export const START_FETCHING_MIX = 'START_FETCHING_MIX';

export const START_FETCHING_MY_COLLECTIONS_RESULTS = 'START_FETCHING_MY_COLLECTIONS_RESULTS';

export const START_FETCHING_NEXT_MY_COLLECTIONS_RESULTS_PAGE = 'START_FETCHING_NEXT_MY_COLLECTIONS_RESULTS_PAGE';

export const START_FETCHING_NEXT_QUERY_RESULTS_PAGE = 'START_FETCHING_NEXT_QUERY_RESULTS_PAGE';

export const START_FETCHING_QUERY_RESULTS = 'START_FETCHING_QUERY_RESULTS';

export const START_PLAYBACK = 'START_PLAYBACK';

export const START_SIGNING_IN = 'START_SIGNING_IN';

export const START_SUBMITTING_COMMENT = 'START_SUBMITTING_COMMENT';

export const START_SUBMITTING_VOTE = 'START_SUBMITTING_VOTE';

export const SUBMIT_COMMENT = 'SUBMIT_COMMENT';

export const SUBMIT_VOTE = 'SUBMIT_VOTE';

export const TOGGLE_FACETTED_SEARCH_ITEM = 'TOGGLE_FACETTED_SEARCH_ITEM';

export const TOGGLE_FACETTED_SEARCH_RELATION = 'TOGGLE_FACETTED_SEARCH_RELATION';

export const UNSET_AUTH = 'UNSET_AUTH';

export const UPSERT_MIX_IN_QUERY_RESULTS = 'UPSERT_MIX_IN_QUERY_RESULTS';
